import { createRouter, createWebHistory } from 'vue-router'
const login = () => import("../views/login.vue")

const routes = [
  {
    path: '/',
    redirect: '/login'   //重定向
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta:{
      showNav: false
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "about" */ '../views/user.vue')
  },
  {
    path: '/item',
    name: 'item',
    component: () => import(/* webpackChunkName: "about" */ '../views/item.vue')
  },
  {
    path: '/barrel',
    name: 'barrel',
    component: () => import(/* webpackChunkName: "about" */ '../views/barrel.vue')
  },
  {
    path: '/odo',
    name: 'odo',
    component: () => import(/* webpackChunkName: "about" */ '../views/odo.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
