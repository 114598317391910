<template>
  <el-header class="el-header"
    ><!-- 头部区 -->
    <div >
      <span>后台管理系统</span>
    </div>
  </el-header>
  <el-button type="info" @click="logout" style="float:right;">{{headinfo}}</el-button>
</template>

  
<script>
export default {
  name: "HeadMenu",
  data() {
    return {
      headinfo:''
    }
  },
  mounted() {
    console.log(localStorage.getItem('userInfo'))
    if(localStorage.hasOwnProperty('userInfo')){
      let user=JSON.parse(localStorage.getItem('userInfo'))
      this.headinfo=user.name+'，退出！'
      this.$router.push({
        path: "/user",
      });
    }else{
      this.headinfo='登录'
      this.$router.push({
        path: "/login",
      });
    }
  },
  methods: {
    logout() {
      if(localStorage.hasOwnProperty('userInfo')){
        localStorage.removeItem('userInfo')
        // this.$router.push({
        //   path: "/login",
        // });
        location.reload();
      }else{
        
      }
    },
  },
};
</script>
  
<style>
.el-header {
  background-color: #c1d8e2;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #000000;
  font-size: 20px;
}
.el-menu-vertical-demo {
  height: 645px;
}
</style>