<template>
    <el-row>
      <el-col>
        <el-menu
          class="el-menu-vertical-demo"
             background-color="#E9EEF3"
             text-color="black" router>
              <el-submeu index="1" >
              <template ><i class="el-icon-message"></i>导航一</template>
              <el-menu-item-group>
                <!--<template slot="title">分组一</template>-->
                <!--设置index-->
                <el-menu-item :index="handleUrl('/user')">用户管理</el-menu-item>
                <el-menu-item :index="handleUrl('/item')">废品管理</el-menu-item>
                <!--<el-menu-item-group title="分组2">-->
                <el-menu-item :index="handleUrl('/barrel')">废桶管理</el-menu-item>
                <el-menu-item :index="handleUrl('/odo')">出库单管理</el-menu-item>
                <!--
                <el-submenu index="1-4">
                  <template slot="title">测试5</template>
                  <el-menu-item index="1-4-1">选项4-1</el-menu-item>
                </el-submenu>
                -->
              </el-menu-item-group>
            </el-submeu>
          </el-menu>
      </el-col>
    </el-row>
</template>

  
<script>
    export default {
      name: 'LeftMenu',
      methods: {
        handleUrl(url) {
          if(localStorage.hasOwnProperty('userInfo')){
            return url
          }else{
            return '/login'
          }
        },
      },
    };
</script>
  
<style>
    .el-menu-vertical-demo{
      height: 645px
    }
</style>