<template>
  <div id="app">
    <el-container>
      <el-header v-if="!$route.meta.showNav">
        <head-menu></head-menu>
      </el-header>
      <el-container>
        <el-aside width="200px" v-if="!$route.meta.showNav">
          <!--引入自定义左侧菜单栏-->
          <left-menu></left-menu>
        </el-aside>
        <el-main>
          <!--引入跳转路由-->
          <router-view ></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import HeadMenu from './components/HeadMenu'
  import LeftMenu from './components/LeftMenu'

  export default {
    name: "App",
    components: {
      HeadMenu,
      LeftMenu
    }
  }
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
